import { FormHelperText, styled, FormLabel, useTheme, FormGroup, Autocomplete, TextField, Box } from '@mui/material';
import * as React from 'react';
import HDButton from '../HDButton';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { reactGAEvents } from '../../commons/commonFunctions';

export interface HDMultipleCitySelectionTypes {
  disabled?: boolean;
  error?: any;
  placeholder?: string;
  id?: string;
  lblText?: string;
  errorMsg?: any;
  errorMsgDup: any;
  sx?: Object;
  value: any[];
  onChange?: any;
  setterFn: (arg0: any[]) => void;
  helperText?: string;
  buttonProps?: {
    text?: string;
    startIcon?: any;
    endIcon?: any;
  };
}

const HDMultipleCitySelection = (props: HDMultipleCitySelectionTypes) => {
  const theme = useTheme()?.palette.mode;
  const [cities, setCities] = React.useState<any>([]);
  let requestOptions = { types: ['(cities)'] };
  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({ requestOptions });

  React.useEffect(() => {
    if (Array.isArray(data)) {
      const cityList = data.map((i) => i.description);
      setCities(cityList);
    }
  }, [data]);

  function handleMultiChange(event: any, value: any, reason: string, index: number) {
    if (reason == 'clear') {
      removeItem(index);
    } else if (reason == 'selectOption') {
      onChangeText(index, value);
    }
  }

  function handleAddIndex() {
    reactGAEvents('my-account', 'add-more-cities');
    props.setterFn([...props.value, '']);
  }

  function removeItem(removeIndex: number) {
    props.setterFn(props.value.filter((item, index) => index !== removeIndex));
  }

  function onChangeText(index: number, value: string) {
    let newArr = props.value;
    newArr[index] = value;
    props.setterFn(newArr);
  }

  function handleAddIndexInital(event: any) {
    props.setterFn([...props.value, event.target.innerHTML]);
  }

  const btnStyle = {
    width: 'max-content',
    padding: 0,
    '&:focus': {
      border: 'none',
    },
  };

  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme === 'light' ? '#646A7C' : '#A9A9A9',
    marginLeft: 0,
    marginTop: '8px',
  }));

  const styledTextField = {
    '& .MuiAutocomplete-input': {
      //padding: '11.5px 14px !important',
      //marginBottom: '15px',
    },
  };

  const autoCompleteStyle = {
    marginBottom: '15px',
  };

  const ErrorIcon = styled('img')(() => ({
    marginRight: '8px',
  }));

  return (
    <FormGroup>
      {props.lblText && (
        <FormLabel sx={{ marginBottom: '14px' }} error={props.error}>
          {props.lblText}
        </FormLabel>
      )}
      {props.value.length === 0 ? (
        <Box sx={autoCompleteStyle}>
          <Autocomplete
            freeSolo
            onChange={(e) => handleAddIndexInital(e)}
            options={cities}
            onClose={() => setCities([])}
            disabled={props.disabled}
            renderInput={(params) => <TextField {...params} value={value} onChange={(event) => setValue(event.target.value)} error={props.error} helperText={props.helperText} sx={styledTextField} />}
          />
          {props.errorMsg && props.errorMsg['0'] && (
            <FormHelperTextStyled error={true}>
              <ErrorIcon src={window.location.origin + '/res/hd-ui-kit-images/' + (theme === 'light' ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
              {props.errorMsg['0']}
            </FormHelperTextStyled>
          )}
        </Box>
      ) : (
        props?.value?.map((item: any, index: number) => {
          return (
            <Box key={`item_${item}`} sx={autoCompleteStyle}>
              <Autocomplete

                freeSolo
                value={item}
                onChange={(event, value, reason) => handleMultiChange(event, value, reason, index)}
                options={cities}
                onClose={() => setCities([])}
                disabled={props.disabled}
                renderInput={(params) => <TextField {...params} value={value} onChange={(event) => setValue(event.target.value)} error={props.errorMsg ? props?.errorMsg[index] : false} helperText={props.helperText} sx={styledTextField} />}
              />
              {props.errorMsg && props.errorMsg[index] && (
                <FormHelperTextStyled error={true}>
                  <ErrorIcon src={window.location.origin + '/res/hd-ui-kit-images/' + (theme === 'light' ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
                  {props.errorMsg[index]}
                </FormHelperTextStyled>
              )}
            </Box>
          );
        })
      )}

      {props?.value && props?.value?.length > 0 && (
        <Box sx={autoCompleteStyle}>
          {props.errorMsgDup && (
            <FormHelperTextStyled error={true}>
              <ErrorIcon src={window.location.origin + '/res/hd-ui-kit-images/' + (theme === 'light' ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
              {props.errorMsgDup}
            </FormHelperTextStyled>
          )}
        </Box>
      )}

      <HDButton text={props?.buttonProps?.text} startIcon={props?.buttonProps?.startIcon} endIcon={props?.buttonProps?.endIcon} onClick={handleAddIndex} variant={'text'} disableRipple={true} sx={btnStyle} />
    </FormGroup>
  );
};

export default HDMultipleCitySelection;
