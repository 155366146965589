import HDButton from '../hd-ui-kit/HDButton';
import HDCheckBox from '../hd-ui-kit/HDCheckBox/HDCheckBox';
import HDTextField from '../hd-ui-kit/HDTextField';
import { fieldStyle, chkBoxStyle, btnStyle, dividerStyle, buttonStylesLink, noteStyle } from '../pages/auth/NewSignupStyles';
import { Box, Grid, Typography, Divider, styled } from '@mui/material';
import Link from '@mui/material/Link';
import React, { useContext } from 'react';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import Api from '../Api';
import { globalContext } from '../store';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';

const originHost = window.location.origin;
const ErrorIcon = styled('img')(() => ({
  marginRight: '8px',
}));

const ErrorStyle = {
  color: '#cc33a1',
  fontFamily: 'Avenir',
  textAlign: 'start',
  fontSize: '16px',
  marginTop: '9px',
};


export const addFirstName = (gaEvent: any, firstName: any, setFirstName: any, errors: any) => {
  return (
    <Box sx={fieldStyle}>
      <HDTextField
        type='text'
        lblText={'First name'}
        value={firstName}
        onChange={({ target }: any) => setFirstName(target.value)}
        error={errors?.firstName}
        errorMsg={errors?.firstName ? errors.firstName : null}
        onClick={() => gaEvent('profile-register', 'first-name')}
        id='signup-first-name'
      />
    </Box>
  );
};

export const addLastName = (gaEvent: any, lastName: any, setLastName: any, errors: any) => {
  return (
    <Box sx={fieldStyle}>
      <HDTextField
        type='text'
        lblText={'Last name'}
        value={lastName}
        onChange={({ target }: any) => setLastName(target.value)}
        error={errors?.lastName}
        errorMsg={errors?.lastName ? errors.lastName : null}
        onClick={() => gaEvent('profile-register', 'last-name')}
        id='signup-Last-name'
      />
    </Box>
  );
};

export const addEmailAddress = (gaEvent: any, email: any, setEmail: any, errors: any) => {
  return (
    <Box sx={fieldStyle}>
      <HDTextField type={'email'}
        lblText={'Email'}
        value={email}
        onChange={({ target }: any) => setEmail(target.value)}
        error={errors?.email}
        errorMsg={errors?.email ? errors.email : null}
        onClick={() => gaEvent('profile-register', 'email')}
        id="signup-email"
      />
    </Box>
  );
};

export const addPassword = (gaEvent: any, password: any, setPassword: any, errors: any) => {
  return (
    <Box sx={fieldStyle}>
      <HDTextField
        type={'password'}
        lblText={'Password'}
        value={password}
        onChange={({ target }: any) => setPassword(target.value)}
        error={errors?.password}
        errorMsg={errors?.password ? errors.password : null}
        onClick={() => gaEvent('profile-register', 'password')}
        id="signup-password"
      />
    </Box>
  );
};

export const confirmPassword = (gaEvent: any, passwordConfrim: any, setPasswordConfirm: any, errors: any) => {
  return (
    <Box sx={fieldStyle}>
      <HDTextField
        type={'password'}
        lblText={'Confirm Password'}
        value={passwordConfrim}
        onChange={({ target }: any) => setPasswordConfirm(target.value)}
        error={errors?.password}
        errorMsg={errors?.password ? errors.password : null}
        onClick={() => gaEvent('profile-register', 'confirm-password')}
        id="signup-confirm-password"
      />
    </Box>
  );
};

export const privacyPolicy = (themeMode: string, gaEvent: any, privacyPolicyChecked: any, setPrivacyPolicyCheck: any, errors: any) => {
  return (
    <Box sx={chkBoxStyle}>
      <HDCheckBox
        id={'privacy_policy'}
        sx={{
          '.MuiFormControlLabel-label': {
            fontWeight: '400',
          },
        }}
        disabled={false}
        labelTxt={'I have read and accept the'}
        value={privacyPolicyChecked}
        onChange={({ target }: any) => {
          gaEvent('profile-register', 'agree-privacy-policy');
          setPrivacyPolicyCheck(target.checked);
        }}
        error={errors?.privacy_policy}
      />
      <Link
        onClick={() => {
          gaEvent('profile-register', 'privacy-policy');
          window.open(`${originHost}/res/privacy-policy.pdf`, '_blank', 'noopener,noreferrer');
        }}
        sx={buttonStylesLink(themeMode)}
        tabIndex={0}
      >
        Privacy Policy
      </Link>

      {errors?.privacy_policy && (
        <Typography variant="h2" sx={ErrorStyle}>
          <ErrorIcon src={window.location.origin + '/res/hd-ui-kit-images/' + (themeMode === 'light' ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
          {errors?.privacy_policy}
        </Typography>
      )}
    </Box>
  );
};

export const termsOfUse = (themeMode: string, gaEvent: any, termsOfUseChecked: any, setTermsOfUseChecked: any, errors: any) => {
  return (
    <Box sx={chkBoxStyle}>
      <HDCheckBox
        id={'terms_of_use'}
        sx={{
          '.MuiFormControlLabel-label': {
            fontWeight: '400',
          },
        }}
        disabled={false}
        labelTxt={'I have read and accept the'}
        value={termsOfUseChecked}
        onChange={({ target }: any) => {
          gaEvent('profile-register', 'agree-privacy-policy');
          setTermsOfUseChecked(target.checked);
        }}
        error={errors?.terms_of_use}
      />
      <Link
        onClick={() => {
          gaEvent('profile-register', 'terms-of-use');
          window.open(`${originHost}/res/terms-of-use.pdf`, '_blank', 'noopener,noreferrer');
        }}
        sx={buttonStylesLink(themeMode)}
        tabIndex={0}
      >
        Terms of Use
      </Link>

      {errors?.terms_of_use && (
        <Typography variant="h2" sx={ErrorStyle}>
          <ErrorIcon src={window.location.origin + '/res/hd-ui-kit-images/' + (themeMode === 'light' ? 'warning-icon.svg' : 'warning-icon-dark.svg')} />
          {errors?.terms_of_use}
        </Typography>
      )}
    </Box>
  );
};

export const mailmeAboutJob = (gaEvent: any, textOrCall: any, setTextOrCall: any) => {
  return (
    <HDCheckBox
      id={'ok_to_contact'}
      sx={{
        '.MuiFormControlLabel-label': {
          fontWeight: '400',
        },
      }}
      disabled={false}
      labelTxt={'You can email me about job opportunities, Heidrick offerings, and leadership insights.'}
      value={textOrCall}
      onChange={({ target }: any) => {
        gaEvent('profile-register', 'ok-to-contact');
        setTextOrCall(target.checked);
      }}
    />
  );
};

export const createAccountBtn = (themeMode: string, gaEvent: any, handleSubmit: any) => {
  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: themeMode == 'light' ? '#66FCFC' : '#18768C',
  }));
  return (
    <HDButton
      variant={'contained'}
      endIcon={<ArrowForwardSharpIconStyled />}
      text={'Create account'}
      onClick={(event) => {
        gaEvent('profile-register', 'create-account');
        handleSubmit(event);
      }}
      sx={btnStyle(themeMode)}
      showAnimation={true}
    />
  );
};

export const dividerWithOr = (themeMode: string) => {
  const Styledgridwrapper = {
    display: 'flex',
    alignItems: 'center',
  };

  const StyledOr = styled(Box)(() => ({
    color: themeMode == 'light' ? '#334255' : '#A9A9A9',
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    textAlign: 'center',
  }));

  return (
    <Grid container sx={Styledgridwrapper}>
      <Grid item sm={5.5} xs={5.5}>
        <Divider sx={dividerStyle(themeMode)} />
      </Grid>
      <Grid item sm={1} xs={1}>
        <StyledOr>or</StyledOr>
      </Grid>
      <Grid item sm={5.5} xs={5.5}>
        <Divider sx={dividerStyle(themeMode)} />
      </Grid>
    </Grid>
  );
};

export const LinkedInBtn = (themeMode: string, gaEvent: any, setErrors: any) => {
  const { state }: any = useLocation();
  const { dispatch } = useContext(globalContext);
  const navigate = useNavigate();
  const originHost = window.location.origin;
  const StyledLinkedIn = {
    backgroundColor: themeMode == 'light' ? '#334255' : '#F1F1F1',
    color: themeMode == 'light' ? '#FFFFFF' : '#002136',
    width: '100%',
    marginTop: '20px',
    paddingTop: '11px',
    paddingBottom: '11px',
    marginLeft: '0px',
  };

  const noteStyle = {
    fontFamily: 'Aventa',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: themeMode == 'light' ? '#002136' : '#CCC',
    marginBottom: '32px',
    marginTop: '32px',
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scope: 'r_emailaddress r_liteprofile',
    onSuccess: (code: any) => {
      const payload = {
        access_code: code,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      };

      Api.tokenExchange(payload)
        .then((response) => {
          if (response.success) {
            Api.getAccount()
              .then((res) => {
                let data = res.response.data;
                dispatch({ type: 'SET_ACCOUNT', payload: data });
                if (data?.personal_details?.first_name && data.opportunity_preferences != null) {
                  navigate(state?.path + state?.search || '/profile-complete-edge');
                } else {
                  navigate('/create-profile/experience');
                }
              })
              .catch(() => {
                navigate('/register');
              });
          } else {
            const { data }:any = response;
            setErrors(data);
          }
        })
        .catch((err) => {
          setErrors(err);
        });
    },
    onError: () => {
      // Errors here are handled in the linkedIn window
    },
  });

  return (
    <>
      <HDButton
        variant={'contained'}
        endIcon={<img alt="LinkedIn logo" src={themeMode == 'light' ? '../res/LinkedInLight.svg' : '../res/LinkedInDark.svg'} />}
        text={'Sign up with LinkedIn'}
        onClick={() => {
          gaEvent('profile-register', 'sign-up-linkedin');
          linkedInLogin();
        }}
        sx={StyledLinkedIn}
      />
      <Typography sx={noteStyle}>
        By clicking the “Signup With LinkedIn” button, you agree to our&nbsp;
        <Link
          onClick={() => {
            gaEvent('profile-register', 'li-privacy policy');
            window.open(`${originHost}/res/privacy-policy.pdf`, '_blank', 'noopener,noreferrer');
          }}
          sx={buttonStylesLink(themeMode)}
          tabIndex={0}
        >
          Privacy Policy
        </Link>
      </Typography>
    </>
  );
};

export const alreadyHavingAccount = (themeMode: string, gaEvent: any, navigate: any, isMobile: boolean) => {
  const boxWrapper = { display: 'flex', alignItems: 'center', marginTop: '32px' };

  const btnBox = {
    marginLeft: '3px',
    marginBottom: '2px',
  };
  return (
    <>
      <Typography sx={{ ...noteStyle, marginBottom: '0px' }}>
        Already have an account?
        <Link
          onClick={() => {
            gaEvent('profile-register', 'sign-in');
            navigate('/login');
          }}
          sx={{ ...buttonStylesLink(themeMode), marginLeft: '5px' }}
          tabIndex={0}
        >
          Sign In
        </Link>
      </Typography>
      <Box sx={boxWrapper}>
        <Box>
          <Typography sx={{ ...noteStyle, marginBottom: '0px', marginTop: '0px', fontSize: isMobile ? '13px' : '14px' }}>Important notice:</Typography>
        </Box>
        <Box sx={btnBox}>
          <Link
            onClick={() => {
              window.open('https://www.heidrick.com/en/privacy/important-notice-employment-recruitment-scams', '_blank', 'noopener,noreferrer');
            }}
            sx={buttonStylesLink(themeMode)}
            tabIndex={0}
          >
            Employment and Recruitment Scams
          </Link>
        </Box>
      </Box>
    </>
  );
};