import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  CssBaseline, styled
} from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import HDTextField from '../../hd-ui-kit/HDTextField';
import { useNavigate } from 'react-router-dom';
import Api from '../../Api';
import Loader from '../../commons/Loader';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { reactGAEvents, backgroundTopRight, backgroundTopRightDark, titleStyle, styledText, styledBoxMainWrapper, styledDividerLeft } from '../../commons/commonFunctions';

const ForgetPassword = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const themeMode = theme.palette.mode;

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
  };

  const MainStyleBoxEmail = {
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '17px',
  };

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: theme.palette.mode == 'light' ? '#66FCFC' : '#18768C',
  }));

  const StyledSignIn = {
    backgroundColor: theme.palette.mode == 'light' ? '#334255' : '#F1F1F1',
    color: theme.palette.mode == 'light' ? '#FFFFFF' : '#002136',
    marginTop: '20px',
  };

  const StyledForgetWrapper = {
    textAlign: 'left',
    marginTop: '20px',
  };

  const backColor = (theme.palette.mode === 'light') ? { ...backgroundTopRight } : { ...backgroundTopRightDark };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    reactGAEvents('forget-password-verify', 'send');
    event.preventDefault();
    const isValidField = validateFields.validate(
      {
        email: email
      },
      validationOptions
    );

    if (isValidField?.error) {
      let errorKeys: any = { email: '' };
      isValidField?.error?.details?.forEach((val) => {
        if (val?.context?.key === 'email') {
          errorKeys.email = val?.message;
        }
      });

      setErrors(errorKeys);
      return;
    }
    setLoading(true);
    Api.forgetPasswordEmail({ email })
      .then((res) => {
        if (res.success) {
          setLoading(false);
          navigate('/reset-activated');
        } else {
          const { data }:any = res;
          setLoading(false);
          setErrors(data);
        }
      })
      .catch((res) => {
        console.log(res);
        setLoading(false);
      });
  };

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) navigate('/home');
  }, []);

  return (
    <>
      <CssBaseline />
      <Header />
      {loading && <Loader />}
      <Box sx={{ ...backColor, display: 'grid' }}>
        <Box sx={styledBoxMainWrapper(isMobile, themeMode)}>
          <Container maxWidth="lg">
            <Box sx={styledDividerLeft(isMobile, theme)}>
              <Box>
                <Box sx={MainStyledBox}>
                  <Typography variant="h2" sx={titleStyle(isMobile, themeMode)}>
                    Forgot password
                  </Typography>
                  <Typography variant="h2" sx={styledText(themeMode)}>
                    Enter the email address associated with your account and we’ll send you a link to
                    reset your password.
                  </Typography>
                </Box>
                <Box sx={MainStyleBoxEmail}>
                  <HDTextField
                    type="text"
                    lblText="Email"
                    onChange={({ target }: any) => setEmail(target.value)}
                    onClick={() => reactGAEvents('forget-password-verify', 'email')}
                    error={errors ? errors.email : false}
                    errorMsg={errors ? errors.email : null}
                    value={email}
                    id="forgot-password-email"
                  />
                </Box>
                <Box sx={StyledForgetWrapper}>
                  <Box>
                    <HDButton
                      variant={'contained'}
                      sx={StyledSignIn}
                      endIcon={<ArrowForwardSharpIconStyled />}
                      text={'Send'}
                      onClick={(e) => handleSubmit(e)}
                      showAnimation={true}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ForgetPassword;
