import { Box, Container, Typography, useMediaQuery, useTheme, CssBaseline, styled } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Loader from '../../commons/Loader';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import Api from '../../Api';
import { useSearchParams, useNavigate } from 'react-router-dom';

const UnsubscribeEmail = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.between(819, 1180));
  const navigate = useNavigate();
  let [params, SetParams] = useSearchParams();
  let uuid = params.get('uuid');

  const ContainerStyle = {
    position: 'relative',
    paddingBottom: isMobile || isTablet ? '168px' : 0,
    minHeight: isIpad ? '820px' : '550px',
  };

  const BackImage = styled(Box)(() => ({
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    backgroundImage: (theme.palette.mode == 'light') ? ((isMobile || isTablet) ? 'url(../res/unsubscribeLightMobile.svg)' : 'url(../res/unsubscribeLight.svg)') : 'url(../res/unsubscribeDark.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: isMobile || isTablet ? 'right bottom' : 'right top',
    right: isMobile || isTablet ? 0 : '-30px',
    top: isMobile || isTablet ? 'auto' : 0,
    bottom: 0,
    backgroundSize: isMobile || isTablet ? '85%' : '45%',
  }));

  const contentWrapStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    marginTop: isMobile || isTablet ? '30px' : '60px',
    paddingBottom: isMobile || isTablet ? 0 : '50px',
    width: isMobile || isTablet ? '100%' : '38%',
  };

  const pageTitle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '41px',
    lineHeight: '54px',
    textAlign: 'left',
    color: theme.palette.mode === 'light' ? '#002136' : '#fff',
  };

  const titleNote = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: theme.palette.mode === 'light' ? '#334255' : '#CCCCCC',
    marginBottom: '40px',
  };

  useEffect(() => {
    const emailPref = () => {
      setLoading(true);
      let payload = { job_opportunities_reminders_opt_in: false, profile_reminders_opt_in: false, assessment_reminders_opt_in: false };
      Api.updateEmailPreferences(payload, uuid)
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    if (uuid) {
      emailPref();
    }
  }, [uuid]);

  const gotoEmailPreference = () => {
    if (Api.isAuthorized()) {
      navigate('/profile-edit?active=0');
    } else {
      navigate('/login', {
        state: {
          fromEmailPRef: true
        }
      });

    }
  };

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Container maxWidth="lg" sx={ContainerStyle}>
        <BackImage></BackImage>
        <Box sx={contentWrapStyle}>
          <Typography sx={pageTitle}>You have been successfully unsubscribed</Typography>
          <Typography sx={titleNote}>You will no longer receive product update emails. Go to your email preferences to re-subscribe and manage other emails.</Typography>
          <HDButton text={'Email Preferences'} variant={'outlined'} onClick={gotoEmailPreference} />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default UnsubscribeEmail;
