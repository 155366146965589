import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, useTheme } from '@mui/material';

export interface HDProfileNavTypes {
  tabsData: any;
  defaultIndex?: number;
  selected: number;
  onChange?: (e: any, panelNumber: number) => void;
}

function HDProfileNavAccordion(props: HDProfileNavTypes) {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  const handleChange = (panelNumber: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const panel = `panel${panelNumber}`;
    setExpanded(isExpanded ? panel : false);
    if (props.onChange) props.onChange(null, panelNumber);
  };

  React.useEffect(() => {
    setExpanded(`panel${props.selected}`);
  }, [props.selected]);

  const AccordionStyle = {
    background: (isDark) ? '#0B0C10' : '#fff'
  };

  const IconColor = {
    color: (isDark) ? '#fff' : '#002136',
  };

  const AccordionSummaryStyle = {
    fontFamily: 'Aventa',
    fontSize: '16px',
    fontWeight: 500,
    color: (isDark) ? '#fff' : '#002136',
    padding: '12px 24px'
  };

  const AccordionDetailsStyle = {
    borderTop: '1px solid ' + ((isDark) ? '#3C3D40' : '#E0E1E5'),
    paddingTop: '20px',
    paddingBottom: '20px',
    background: (isDark) ? 'radial-gradient(80.1% 175px at 9.25% 0%, rgb(0, 33, 54) 0%, rgb(0, 33, 54) 28.91%, rgba(7, 29, 45, 0.22) 63.28%, rgba(11, 12, 14, 0) 83.07%) rgb(11, 12, 16)' : 'radial-gradient(160.76% 50.42% at 6.02% 0%, #E3ECEF 0%, rgba(241, 241, 241, 0) 40%) #FFF'
  };

  return (
    <Box sx={{ width: '100%' }}>
      {props.tabsData.map((element: any, idx: number) => {
        return (
          <Accordion
            expanded={expanded === `panel${idx}`}
            onChange={handleChange(idx)}
            sx={AccordionStyle}
            key={`tabsData_${element[1]}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={IconColor} />}
              aria-controls={`panel${idx}bh-content`}
              id={`panel${idx}bh-header`}
              sx={AccordionSummaryStyle}
            >
              <Typography >
                {element[1]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={AccordionDetailsStyle}>
              {element[0]}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default HDProfileNavAccordion;