import * as React from 'react';
import { Box, useTheme, useMediaQuery, styled } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HDProfileNavAccordion from './HDProfileNavAccordion';
import HDNotificationBanner from '../HDNotificationBanner';
import Divider from '@mui/material/Divider';

export interface HDProfileNavTypes {
  tabsData: any;
  selectedTab?: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  success: boolean;
  emailSuccess: boolean;
  showHeader?: boolean;
  headerText?: string;
  tabWidth?: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  theme: boolean;
  tabWidth?: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, tabWidth, ...other } = props;

  const tabPanelStyle = {
    width: tabWidth ? tabWidth : '100%',
    minHeight: '85vh',
  };

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} style={tabPanelStyle} {...other}>
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function HDProfileNav(props: HDProfileNavTypes) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const WrapStyle = {
    flexGrow: 1,
    display: 'flex',
  };

  const TabStyle = {
    '.MuiTabs-indicator': {
      left: 0,
    },
    '& .MuiTab-root.Mui-selected': {
      color: isDark ? '#fff' : '#002136',
    },
    backgroundColor: isDark ? '#0B0C10' : '#fff',
    borderRight: '1px solid ' + (isDark ? '#3C3D40' : '#E0E1E5'),
    paddingTop: props.showHeader ? '110px' : '64px',
    width: '35%',
  };

  const TabIndicatorProps = {
    style: {
      height: '24px',
      backgroundColor: isDark ? '#2CDADA' : '#18768C',
      marginTop: '12px',
    },
  };

  const TabsStyle = {
    alignItems: 'flex-start',
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
    color: isDark ? '#A9A9A9' : '#646A7C',
    textTransform: 'none',
    padding: '12px 60px 12px 20px',
  };

  const bannerStyled = { textAlign: 'left' };

  const getSuccessStatus = (name: string) => {
    switch (name) {
      case 'Account Details':
        return 'Account Details';
      case 'Personal Details':
        return 'Personal Details';
      case 'Experience':
        return 'Experience Details';
      case 'Compensation':
        return 'Compensation Details';
      case 'Opportunity Preference':
        return 'Opportunity Preference Details';
      case 'Location Preference':
        return 'Location Preference Details';
      case 'My Resume':
        return 'Resume Details';
      default:
        break;
    }
  };

  const TabHeader = styled(Box)(() => ({
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '20px',
    color: isDark ? '#fff' : '#002136',
    padding: '0px 29px 20px 20px',
  }));

  const DividerStyled = styled(Divider)(() => ({
    borderColor: isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(100, 106, 124, 0.2)',
    width: '85%',
    left: '21px',
    position: 'relative',
  }));

  const getSuccessMessage = ()=>{
    const isAccountDetails = props?.tabsData?.[props.selectedTab]?.['1'] === 'Account Details';
    const successMessage = `Your ${getSuccessStatus(props?.tabsData?.[props.selectedTab]?.['1'])} have been successfully updated`;

    if (isAccountDetails && props.emailSuccess) {
      return 'Confirmation email has been sent to the new email. A notification email has been sent to the current email.';
    } else {
      return successMessage;
    }
  };

  return (
    <Box sx={WrapStyle}>
      {!isMobile ? (
        <>
          {props.showHeader && (
            <Box sx={{ position: 'absolute', marginTop: '60px' }}>
              <TabHeader>{props.headerText}</TabHeader>
              <DividerStyled />
            </Box>
          )}

          <Tabs orientation="vertical" variant="scrollable" value={props.selectedTab} onChange={props.handleChange} aria-label="Vertical tabs example" sx={TabStyle} TabIndicatorProps={TabIndicatorProps}>
            {props.tabsData.map((element: any, indx: number) => {
              return <Tab label={element[1]} key={`profileNav_${element[1]}`} {...a11yProps(indx)} sx={TabsStyle} />;
            })}
          </Tabs>

          {props.tabsData.map((element: any, indx: number) => {
            return (
              <TabPanel value={props.selectedTab} key={`profileNav_${element[1]}`} index={indx} theme={isDark} tabWidth={props.tabWidth}>
                {props.success && (
                  <HDNotificationBanner
                    style={bannerStyled}
                    content={getSuccessMessage()}
                    severity={'info'}
                    variant={'filled'}
                    borderRadius="0px"
                  />
                )}
                <Box>{element[0]}</Box>
              </TabPanel>
            );
          })}
        </>
      ) : (
        <Box sx={{ width: '100%' }}>
          {props.success && (
            <HDNotificationBanner
              style={bannerStyled}
              content={getSuccessMessage()}
              severity={'info'}
              variant={'filled'}
              borderRadius="0px"
            />
          )}
          <HDProfileNavAccordion tabsData={props.tabsData} onChange={props.handleChange} selected={props.selectedTab} />
        </Box>
      )}
    </Box>
  );
}

export default HDProfileNav;
