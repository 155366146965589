import { Drawer, IconButton, styled, Box, Collapse, MenuList, MenuItem, Menu, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import { ExpandLess, ExpandMore, Close, Dehaze } from '@mui/icons-material';
import Api from './../../Api';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export interface HDNavigationTypes {
  darkLogo: string | undefined;
  lightLogo: string | undefined;
  navItems: any[];
  myAccount: any[];
  onNavigationClick: (arg0: string) => void;
  accountIcon?: any;
}

const HDNavigation = (props: HDNavigationTypes) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(1, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }));

  const DeskNavigationWrap = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  }));

  const DeskMenuList = styled(MenuList)(() => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: '20px',
  }));

  const DeskMenuItemBar = styled(MenuItem)(() => ({
    borderLeft: '1px solid',
    marginLeft: '15px',
    height: '38px',
    pointerEvents: 'none',
  }));

  const DeskMenuItem = styled(MenuItem)(() => ({
    marginLeft: '30px',
    height: '38px',
    padding: 0,
    borderBottom: '3px solid transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }));

  const StyledMenuItem = styled(MenuItem)((theme) => ({
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
    padding: 0,
  }));

  const StyledAccountMenuItem = styled(MenuItem)(() => ({
    padding: '15px 20px 15px 20px',
  }));

  const StyledAccountIcon = styled(IconButton)(() => ({
    //padding: '3%',
    border: '1px solid' + ((isDarkTheme) ? '#646A7C' : '#CCCCCC'),
    height: '50px',
    borderRadius: '30px',
    padding: '12px',
    width: '80px',
    display: 'flex',
    ':hover': {
      background: isDarkTheme ? '#002136' : '#E7E7E7',
      border: '1px solid' + ((isDarkTheme) ? '#FFFFFF' : '#0B0C10'),
    },
  }));

  const activeOpt = {
    borderColor: isDarkTheme ? '#2cdada' : '#18768c',
  };

  const DrawerComponent = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    const StyledNavBarWrapBox = styled(Box)((theme) => ({
      padding: '0 20px',
    }));

    const StyledMenuListCollapsed = styled(MenuList)(() => ({
      width: '100%',
      padding: '0 25px',
    }));

    const StyledIconButtonMobile = styled(IconButton)(() => ({
      padding: '5%',
    }));

    const MobileLogoWrap = styled(Box)(() => ({
      margin: '15px 0',
      cursor: 'pointer',
    }));

    const MobileMenuIconWrap = styled(Box)(() => ({
      position: 'absolute',
      right: '20px',
    }));

    return (
      <>
        <MobileMenuIconWrap>
          <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} anchor={'left'}>
            <DrawerHeader>
              <MobileLogoWrap>
                <img alt='Application logo' src={isDarkTheme ? props?.darkLogo : props?.lightLogo} />
              </MobileLogoWrap>
              <Box>
                <IconButton color='inherit' onClick={() => setOpenDrawer(false)}>
                  <Close fontSize='large' />
                </IconButton>
              </Box>
            </DrawerHeader>
            <Divider />

            <StyledNavBarWrapBox>
              <MenuList>
                {props.navItems.map((element: any, index: number) => {
                  return (
                    <>
                      <StyledMenuItem key={`navItems_${element.url}`} onClick={() => onNavClick(element.url)}>
                        <a style={{ color: 'inherit', textDecoration: 'none' }} href='javascript:void(0)' key={`navItems_${element.itemName}`} >
                          {element.itemName}
                        </a>
                      </StyledMenuItem>
                      <Divider />
                    </>
                  );
                })}
                <StyledMenuItem onClick={() => handleClick()}>
                  <p style={{ width: '90%' }}>My Account</p>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </StyledMenuItem>

                <Collapse in={open} timeout='auto' unmountOnExit>
                  <StyledMenuListCollapsed disablePadding>
                    {props.myAccount.map((element: any, index: number) => {
                      return (
                        <>
                          <StyledAccountMenuItem key={`myAccount_${element.url}`} onClick={() => onNavClick(element.url)}>
                            <a style={{ color: 'inherit', textDecoration: 'none' }} href='javascript:void(0)' key={`myAccount_${element.itemName}`}>
                              {element.itemName}
                            </a>
                          </StyledAccountMenuItem>
                          <Divider />
                        </>
                      );
                    })}
                  </StyledMenuListCollapsed>
                </Collapse>
                <Divider />
              </MenuList>
            </StyledNavBarWrapBox>
          </Drawer>
          {Api.isAuthorized() && (
            <StyledIconButtonMobile color='inherit' onClick={() => setOpenDrawer(!openDrawer)}>
              <Dehaze fontSize='large' />
            </StyledIconButtonMobile>
          )}
        </MobileMenuIconWrap>
      </>
    );
  };

  const AccountMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [rotateIcon, setRotateIcon] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setRotateIcon(true);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setRotateIcon(false);
    };

    const rotateStyle = {
      transform: 'rotate(180deg)',
    };

    return (
      <>
        <StyledAccountIcon aria-controls='account-menu' aria-haspopup='true' color='inherit' onClick={(e) => handleClick(e)} disableRipple={true}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <img src={props.accountIcon} alt='Profile icon' />
            <KeyboardArrowDownOutlinedIcon sx={(rotateIcon) ? rotateStyle : {}} />
          </Box>
        </StyledAccountIcon>

        <Menu
          id='account-menu'
          anchorEl={anchorEl}
          keepMounted={false}
          open={open}
          onClose={handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '.MuiPaper-root': {
              border: '1px solid' + ((isDarkTheme) ? '#3D5666' : '#D5D6DA'),
            },
            '.MuiBackdrop-root': {
              backgroundColor: 'unset',
            },
            //transform: 'translateX(10px) translateY(18px)',
            top: '18px',
            position: 'absolute'
          }}
        >
          {props.myAccount.map((element: any, index: number) => {
            return (
              <StyledAccountMenuItem key={`myAccount_${element.url}`} onClick={() => onNavClick(element.url)}>
                <a style={{ color: 'inherit', textDecoration: 'none' }} href='javascript:void(0)' key={`myAccount_${element.itemName}`} >
                  {element.itemName}
                </a>
              </StyledAccountMenuItem>
            );
          })}
        </Menu>
      </>
    );
  };

  function onNavClick(url: string) {
    props.onNavigationClick(url);
  }

  return (
    <>
      {isMobile ? (
        <DrawerComponent />
      ) : (
        <DeskNavigationWrap>
          <DeskMenuList>
            {(Api.isAuthorized() && props.navItems.length > 0) && <DeskMenuItemBar></DeskMenuItemBar>}
            {props.navItems.map((element: any, index: number) => {
              return (
                <DeskMenuItem onClick={() => onNavClick(element.url)} key={`navItems_${element.url}`} sx={element.className && activeOpt}>
                  <a style={{ color: 'inherit', textDecoration: 'none' }} href='javascript:void(0)' >
                    {element.itemName}
                  </a>
                </DeskMenuItem>
              );
            })}
          </DeskMenuList>
          {Api.isAuthorized() && <AccountMenu />}
        </DeskNavigationWrap>
      )}
    </>
  );
};

export default HDNavigation;
