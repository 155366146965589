import { COUNTRIES } from './countries';

const genderMenu = ['Female', 'Male', 'Other', 'Declined to Self-Identify'];
const genderMenuNew = [{ keyName: 'Female', keyValue: 'Female' }, { keyName: 'Male', keyValue: 'Male' }, { keyName: 'Other', keyValue: 'Other' },
{ keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' }
];
const declineSelectDefault = 'Declined to Self-Identify';

// const lgbtqMenu = ['Yes', 'No', 'Declined to Self-Identify']

function lgbtqMenu(country: string) {
  if (!country) {
    // disable select for lgbtq until United States of America, United Kingdom, CAN, or AUS
    return [];
  }

  if (country === 'United States of America' || country === 'United Kingdom') {
    return ['Yes', 'No', 'Declined to Self-Identify'];
  }

  return [];
}

function lgbtqMenuNew(country: string) {
  if (!country) {
    // disable select for lgbtq until United States of America, United Kingdom, CAN, or AUS
    return [];
  }

  if (country === 'United States of America' || country === 'United Kingdom') {
    return [{ keyName: 'Yes', keyValue: 'Yes' }, { keyName: 'No', keyValue: 'No' }, { keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' }];
  }

  return [];
}

function diversityMenu(country: any) {
  if (!country) {
    return [];
  }
  return ['Yes', 'No', 'Declined to Self-Identify'];
}

function diversityMenuNew(country: any) {
  if (!country) {
    return [];
  }
  return [{ keyName: 'Yes', keyValue: 'Yes' }, { keyName: 'No', keyValue: 'No' }, { keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' }];
}

function ethnicityMenu(country: string) {
  if (!country) {
    // disable select for ethnicity until United States of America, United Kingdom, CAN, or AUS
    return ['Declined to Self-Identify'];
  }
  if (country === 'United States of America') {
    return [
      'Black/African Descent',
      'Asian Descent',
      'Pacific Islander/Hawaiian',
      'Hispanic/Latinx',
      'Indigenous',
      'Other (Minority Ethnic)',
      'White/Caucasian',
      'Declined to Self-Identify',
    ];
  } else if (country === 'United Kingdom') {
    return [
      'Black/African Descent',
      'Asian Descent',
      'Other (Minority Ethnic)',
      'White/Caucasian',
      'Declined to Self-Identify',
    ];
  } else if (country === 'Canada') {
    return [
      'Black/African Descent',
      'Asian Descent',
      'Indigenous',
      'Other (Minority Ethnic)',
      'White/Caucasian',
      'Declined to Self-Identify',
    ];
  } else if (country === 'Australia') {
    return [
      'Indigenous',
      'Other (Minority Ethnic)',
      'White/Caucasian',
      'Declined to Self-Identify',
    ];
  } else {
    // disable
    return ['Declined to Self-Identify'];
  }
}

function ethnicityMenuNew(country: string) {
  if (!country) {
    // disable select for ethnicity until United States of America, United Kingdom, CAN, or AUS
    return [{ keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' }];
  }
  if (country === 'United States of America') {
    return [
      { keyName: 'Black/African Descent', keyValue: 'Black/African Descent' },
      { keyName: 'Asian Descent', keyValue: 'Asian Descent' },
      { keyName: 'Pacific Islander/Hawaiian', keyValue: 'Pacific Islander/Hawaiian' },
      { keyName: 'Hispanic/Latinx', keyValue: 'Hispanic/Latinx' },
      { keyName: 'Indigenous', keyValue: 'Indigenous' },
      { keyName: 'Other (Minority Ethnic)', keyValue: 'Other (Minority Ethnic)' },
      { keyName: 'White/Caucasian', keyValue: 'White/Caucasian' },
      { keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' },
    ];
  } else if (country === 'United Kingdom') {
    return [
      { keyName: 'Black/African Descent', keyValue: 'Black/African Descent' },
      { keyName: 'Asian Descent', keyValue: 'Asian Descent' },
      { keyName: 'Other (Minority Ethnic)', keyValue: 'Other (Minority Ethnic)' },
      { keyName: 'White/Caucasian', keyValue: 'White/Caucasian' },
      { keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' },
    ];
  } else if (country === 'Canada') {
    return [
      { keyName: 'Black/African Descent', keyValue: 'Black/African Descent' },
      { keyName: 'Asian Descent', keyValue: 'Asian Descent' },
      { keyName: 'Indigenous', keyValue: 'Indigenous' },
      { keyName: 'Other (Minority Ethnic)', keyValue: 'Other (Minority Ethnic)' },
      { keyName: 'White/Caucasian', keyValue: 'White/Caucasian' },
      { keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' },
    ];
  } else if (country === 'Australia') {
    return [
      { keyName: 'Indigenous', keyValue: 'Indigenous' },
      { keyName: 'Other (Minority Ethnic)', keyValue: 'Other (Minority Ethnic)' },
      { keyName: 'White/Caucasian', keyValue: 'White/Caucasian' },
      { keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' },
    ];
  } else {
    // disable
    return [{ keyName: 'Declined to Self-Identify', keyValue: 'Declined to Self-Identify' }];
  }
}

const jobLevelMenu = [
  'Individual Contributor',
  'Manager',
  'Senior Manager',
  'Vice President/Senior Vice President',
  'C-Level (Reports to CEO)',
  'CEO (Reports to Board of Directors)',
  'Board of Directors Member',
  'Other',
];

const jobLevelMenuNew = [
  { keyName: 'Individual Contributor', keyValue: 'Individual Contributor' },
  { keyName: 'Manager', keyValue: 'Manager' },
  { keyName: 'Senior Manager', keyValue: 'Senior Manager' },
  { keyName: 'Vice President/Senior Vice President', keyValue: 'Vice President/Senior Vice President' },
  { keyName: 'C-Level (Reports to CEO)', keyValue: 'C-Level (Reports to CEO)' },
  { keyName: 'CEO (Reports to Board of Directors)', keyValue: 'CEO (Reports to Board of Directors)' },
  { keyName: 'Board of Directors Member', keyValue: 'Board of Directors Member' },
  { keyName: 'Other', keyValue: 'Other' },
];

const jobFunctionMenu = [
  'Prefer not to respond',
  'Board of Directors',
  'Chief Executive Officer (Corporate)',
  'Chief Executive Officer (Division)',
  'Finance',
  'General Management',
  'Human Resources',
  'Legal, Risk, & Compliance',
  'Marketing, Sales, & Strategy',
  'Supply Chain & Operations',
  'Technology',
  'Other',
];

const jobFunctionMenuNew = [
  { keyName: 'Prefer not to respond', keyValue: 'Prefer not to respond' },
  { keyName: 'Board of Directors', keyValue: 'Board of Directors' },
  { keyName: 'Chief Executive Officer (Corporate)', keyValue: 'Chief Executive Officer (Corporate)' },
  { keyName: 'Chief Executive Officer (Division)', keyValue: 'Chief Executive Officer (Division)' },
  { keyName: 'General Management', keyValue: 'General Management' },
  { keyName: 'Human Resources', keyValue: 'Human Resources' },
  { keyName: 'Legal, Risk, & Compliance', keyValue: 'Legal, Risk, & Compliance' },
  { keyName: 'Marketing, Sales, & Strategy', keyValue: 'Marketing, Sales, & Strategy' },
  { keyName: 'Supply Chain & Operations', keyValue: 'Supply Chain & Operations' },
  { keyName: 'Technology', keyValue: 'Technology' },
  { keyName: 'Other', keyValue: 'Other' },
];
const industryMenuNew = [
  { keyName: 'Consumer Markets', keyValue: 'Consumer Markets' },
  { keyName: 'Social Impact', keyValue: 'Social Impact' },
  { keyName: 'Financial Services', keyValue: 'Financial Services' },
  { keyName: 'Global Technology & Services', keyValue: 'Global Technology & Services' },
  { keyName: 'Industrial', keyValue: 'Industrial' },
  { keyName: 'Healthcare and Life Sciences', keyValue: 'Healthcare and Life Sciences' },
];

const companySize = [
  { keyName: '1-10', keyValue: '1-10' },
  { keyName: '11-100', keyValue: '11-100' },
  { keyName: '101-500', keyValue: '101-500' },
  { keyName: '501-1,000', keyValue: '501-1,000' },
  { keyName: '1,001-5,000', keyValue: '1,001-5,000' },
  { keyName: '5,001-10,000', keyValue: '5,001-10,000' },
  { keyName: '10,001+', keyValue: '10,001+' },
];

const industryMenu = [
  'Consumer Markets',
  'Social Impact',
  'Financial Services',
  'Global Technology & Services',
  'Industrial',
  'Healthcare and Life Sciences',
];

function industrySector(industry: string) {
  if (!industry) {
    return [];
  }

  if (industry === 'Consumer Markets') {
    return [
      'Consumer Products',
      'Hospitality & Leisure',
      'Media & Entertainment',
      'Retail & Direct To Consumer',
    ];
  } else if (industry === 'Social Impact') {
    return [
      'Associations',
      'Academia Research & Education',
      'Foundations & Philanthropies',
      'Government & Policy',
      'Non-profit & NGOs',
    ];
  } else if (industry === 'Financial Services') {
    return [
      'Insurance',
      'Payments & Market Infrastructure',
      'Wealth & Asset Managements',
      'Banking',
    ];
  } else if (industry === 'Global Technology & Services') {
    return [
      'Services',
      'Digital Infrastructure',
      'Semi-Conductor',
      'Software',
    ];
  } else if (industry === 'Industrial') {
    return [
      'Energy',
      'Industrial Goods & Technology',
      'Process Industries',
      'Aerospace Defense & Aviation',
      'Automotive',
      'Diversified Services',
    ];
  } else if (industry === 'Healthcare and Life Sciences') {
    return [
      'Life Sciences Tools & Services',
      'Healthcare Services',
      'Medical Technology',
      'Biopharmaceuticals',
    ];
  }  else {
    // disable
    return [];
  }
}
function industrySectorNew(industry: string) {
  if (!industry) {
    return [];
  }

  if (industry === 'Consumer Markets') {
    return [
      { keyName: 'Consumer Products', keyValue: 'Consumer Products' },
      { keyName: 'Hospitality & Leisure', keyValue: 'Hospitality & Leisure' },
      { keyName: 'Media & Entertainment', keyValue: 'Media & Entertainment' },
      { keyName: 'Retail & Direct To Consumer', keyValue: 'Retail & Direct To Consumer' },
    ];
  } else if (industry === 'Social Impact') {
    return [
      { keyName: 'Associations', keyValue: 'Associations' },
      { keyName: 'Academia Research & Education', keyValue: 'Academia Research & Education' },
      { keyName: 'Foundations & Philanthropies', keyValue: 'Foundations & Philanthropies' },
      { keyName: 'Government & Policy', keyValue: 'Government & Policy' },
      { keyName: 'Non-profit & NGOs', keyValue: 'Non-profit & NGOs' },
    ];
  } else if (industry === 'Financial Services') {
    return [
      { keyName: 'Insurance', keyValue: 'Insurance' },
      { keyName: 'Payments & Market Infrastructure', keyValue: 'Payments & Market Infrastructure' },
      { keyName: 'Wealth & Asset Managements', keyValue: 'Wealth & Asset Managements' },
      { keyName: 'Banking', keyValue: 'Banking' },
    ];
  } else if (industry === 'Global Technology & Services') {
    return [
      { keyName: 'Services', keyValue: 'Services' },
      { keyName: 'Digital Infrastructure', keyValue: 'Digital Infrastructure' },
      { keyName: 'Semi-Conductor', keyValue: 'Semi-Conductor' },

      { keyName: 'Software', keyValue: 'Software' },
    ];
  } else if (industry === 'Industrial') {
    return [
      { keyName: 'Energy', keyValue: 'Energy' },
      { keyName: 'Industrial Goods & Technology', keyValue: 'Industrial Goods & Technology' },
      { keyName: 'Process Industries', keyValue: 'Process Industries' },
      { keyName: 'Aerospace Defense & Aviation', keyValue: 'Aerospace Defense & Aviation' },
      { keyName: 'Automotive', keyValue: 'Automotive' },
      { keyName: 'Diversified Services', keyValue: 'Diversified Services' },
    ];
  } else if (industry === 'Healthcare and Life Sciences') {
    return [
      { keyName: 'Life Sciences Tools & Services', keyValue: 'Life Sciences Tools & Services' },
      { keyName: 'Healthcare Services', keyValue: 'Healthcare Services' },
      { keyName: 'Medical Technology', keyValue: 'Medical Technology' },
      { keyName: 'Biopharmaceuticals', keyValue: 'Biopharmaceuticals' },
    ];
  } else {
    // disable
    return [];
  }
}

const EmployerTypeList = [
  { keyName: 'Private company', keyValue: 'Private company' },
  { keyName: 'Publicly traded company', keyValue: 'Publicly traded company' },
  { keyName: 'Other', keyValue: 'Other' },
  { keyName: 'Decline to answer', keyValue: 'Decline to answer' },
];

const SalaryRangeList = [
  { keyName: '<$99k', keyValue: '<$99k' },
  { keyName: '$100k - 150k', keyValue: '$100k - 150k' },
  { keyName: '$151k - $200k', keyValue: '$151k - $200k' },
  { keyName: '$201k - $250k', keyValue: '$201k - $250k' },
  { keyName: '$251k - $300k', keyValue: '$251k - $300k' },
  { keyName: '$301k - $400k', keyValue: '$301k - $400k' },  
  { keyName: '$401k - $500k', keyValue: '$401k - $500k' },  
  { keyName: '$501k - $750k', keyValue: '$501k - $750k' },  
  { keyName: '$750k - $1m', keyValue: '$750k - $1m' },  
  { keyName: '> 1 m', keyValue: '> 1 m' },  
  { keyName: 'Decline to answer', keyValue: 'Decline to answer' },
];

const AnnualBaseSalaryPercentList = [
  { keyName: '<10% of annual base salary', keyValue: '<10% of annual base salary' },
  { keyName: '10 to 15% of annual base salary', keyValue: '10 to 15% of annual base salary' },
  { keyName: '16 to 20% of annual base salary', keyValue: '16 to 20% of annual base salary' },
  { keyName: '21 to 25% of annual base salary', keyValue: '21 to 25% of annual base salary' },
  { keyName: '26 to 50% of annual base salary', keyValue: '26 to 50% of annual base salary' },
  { keyName: '51 to 75% of annual base salary', keyValue: '51 to 75% of annual base salary' },  
  { keyName: '76 to 100% of annual base salary', keyValue: '76 to 100% of annual base salary' },  
  { keyName: '101 to 150% of annual base salary', keyValue: '101 to 150% of annual base salary' },  
  { keyName: '151 to 200% of annual base salary', keyValue: '151 to 200% of annual base salary' },  
  { keyName: '> 200% of annual base salary', keyValue: '> 200% of annual base salary' },  
  { keyName: 'Decline to answer', keyValue: 'Decline to answer' },
];

const CompensationPackageAttributesList = [
  { text: 'Stock Options (ISOs or NQSOs)', value: 'Stock Options (ISOs or NQSOs)' },
  { text: 'Stock Appreciation Rights', value: 'Stock Appreciation Rights' },
  { text: 'Time-based Restricted Stock / Restricted Stock Units', value: 'Time-based Restricted Stock / Restricted Stock Units' },
  { text: 'Performance Shares / Units', value: 'Performance Shares / Units' },
  { text: 'Long-term Cash Plans', value: 'Long-term Cash Plans' },
  { text: 'Not Applicable', value: 'Not Applicable' },   
  { text: 'Decline to answer', value: 'Decline to answer' },
];

const GrantedFrequencyOfCompensationList = [
  { keyName: 'Initial one-time grant', keyValue: 'Initial one-time grant' },
  { keyName: 'Annually', keyValue: 'Annually' },
  { keyName: 'Twice a year', keyValue: 'Twice a year' },
  { keyName: 'Quarterly', keyValue: 'Quarterly' },
  { keyName: 'Every Other Year', keyValue: 'Every Other Year' },
  { keyName: 'Other', keyValue: 'Other' },   
  { keyName: 'Not Applicable', keyValue: 'Not Applicable' },   
  { keyName: 'Decline to answer', keyValue: 'Decline to answer' },
];

const HistoryWithHeidrickList = [
  { keyName: 'Heidrick Consultant', keyValue: 'Heidrick Consultant' },
  { keyName: 'I have been a candidate with Heidrick Search', keyValue: 'I have been a candidate with Heidrick Search' },
  { keyName: 'Friend or Colleague', keyValue: 'Friend or Colleague' },
  { keyName: 'H&S Website', keyValue: 'H&S Website' },
  { keyName: 'Internet Search', keyValue: 'Internet Search' },
  { keyName: 'Marketing Campaign', keyValue: 'Marketing Campaign' },    
  { keyName: 'Other', keyValue: 'Other' }, 
];

const IncentivesGrantedLastYearList = [
  { keyName: '< $50k', keyValue: '< $50k' },
  { keyName: '$51k - $100k', keyValue: '$51k - $100k' },
  { keyName: '$101k - $200k', keyValue: '$101k - $200k' },
  { keyName: '$201k - $300k', keyValue: '$201k - $300k' },
  { keyName: '$301k - $400k', keyValue: '$301k - $400k' },
  { keyName: '$401k - $500k', keyValue: '$401k - $500k' },   
  { keyName: '$501k - $750k', keyValue: '$501k - $750k' },   
  { keyName: '$751k - $1m', keyValue: '$751k - $1m' },
  { keyName: '$1m - $2m', keyValue: '$1m - $2m' },
  { keyName: '$2m - $3m', keyValue: '$2m - $3m' },
  { keyName: '$3m - $4m', keyValue: '$3m - $4m' },
  { keyName: '$4m - $5m', keyValue: '$4m - $5m' },
  { keyName: '$5m - $10m', keyValue: '$5m - $10m' },
  { keyName: '$10m - $20m', keyValue: '$10m - $20m' },
  { keyName: '$20m - $50m', keyValue: '$20m - $50m' },
  { keyName: '> $50m', keyValue: '> $50m' }, 
  { keyName: 'Not Applicable', keyValue: 'Not Applicable' },   
  { keyName: 'Decline to answer', keyValue: 'Decline to answer' },
];



const alignPurposeList = [
  'Creativity',
  'Flexibility',
  'Legacy',
  'Certainty',
  'Quality',
  'Cohesion',
  'Service',
  'Equity',
  'Competition',
  'Authority',
];

const alignStrengthList = [
  'Put Customer First',
  'Shape Strategy',
  'Inspire and Influence',
  'Build Talent and Teams',
  'Drive for Results',
  'Disrupt and Challenge',
  'Lead Innovation',
  'Foresight',
  'Learning',
  'Resilience',
  'Adaptability',
];

const countryFormList = COUNTRIES.map((country) => country.country_value);
const countryList = COUNTRIES.map((country) => { return { keyValue: country.country_value, keyName: country.country_value }; });

export default {
  genderMenu,
  genderMenuNew,
  lgbtqMenu, lgbtqMenuNew,
  diversityMenu,
  diversityMenuNew,
  ethnicityMenu,
  ethnicityMenuNew,
  jobLevelMenu,
  jobLevelMenuNew,
  jobFunctionMenu,
  jobFunctionMenuNew,
  industryMenu,
  industryMenuNew,
  industrySector,
  industrySectorNew,
  alignPurposeList,
  alignStrengthList,
  countryFormList,
  countryList,
  declineSelectDefault,
  companySize,
  EmployerTypeList,
  SalaryRangeList,
  AnnualBaseSalaryPercentList,
  CompensationPackageAttributesList,
  GrantedFrequencyOfCompensationList,
  IncentivesGrantedLastYearList,
  HistoryWithHeidrickList
};