import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme, CssBaseline } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import HDButton from '../../../hd-ui-kit/HDButton';
import HDCheckBox from '../../../hd-ui-kit/HDCheckBox/HDCheckBox';
import HDTextField from '../../../hd-ui-kit/HDTextField';
import Api from '../../../Api';
import HDModal from '../../../hd-ui-kit/HDModal';

import { headerStyle, pageWrapper, titleUnderline, btnStyle, btnIconStyle, btnWrapper, textFieldStyle } from './styles';
import HDNotificationBanner from '../../../hd-ui-kit/HDNotificationBanner';
import { validateFields, validationOptions } from '../../../ValidationSchema';
import { reactGAEvents } from '../../../commons/commonFunctions';
import HDSpinner from '../../../hd-ui-kit/HDSpinner/HDSpinner';
import { globalContext } from '../../../store';
import Link from '@mui/material/Link';

function AccountDetails(props: any) {
  const theme = useTheme();
  const themeMode = theme.palette.mode;
  const passwordForm = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [email, setEmail] = useState<string>('');
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [SuccessMsg, setSuccessMsg] = useState(false);
  const [jobOpt_in, setJobOpt_in] = useState(false);
  const [profileOpt_in, setProfileOpt_in] = useState(false);
  const [assessmentOpt_in, setAssessmentOpt_in] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const { dispatch } = useContext(globalContext);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    if (data) {
      setEmail(data.email);
      setJobOpt_in(data.job_opportunities_reminders_opt_in);
      setProfileOpt_in(data.profile_reminders_opt_in);
      setAssessmentOpt_in(data.assessment_reminders_opt_in);
    }
  }, [data]);

  const textFieldWrap = {
    marginTop: '60px',
    marginBottom: '60px',
  };

  const passwordButtonStyle = {
    marginTop: '2rem',
  };

  const bannerStyled = { textAlign: 'left', marginBottom: '10px' };

  const chkBoxStyle = {
    marginTop: '16px',
    border: '2px solid transparent',
  };

  const headerStyleEmail = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    color: themeMode === 'light' ? '#002136' : '#fff',
    marginBottom: '16px',
  };
  const StyledEmail = { marginTop: '30px' };
  const sxDeleteButton = {
    color: themeMode === 'light' ? '#18768C' : '#2CDADA',
    outline: 'none',
    background: 'none',
    textDecoration: 'underline',
    fontWeight: 400,
    fontSize: '16px',
    cursor: 'pointer'
  };

  const onSavePassword = (event: { preventDefault: () => void }) => {
    reactGAEvents('my-account', 'save-password');
    event.preventDefault();
    props.onSuccess(false);
    const form = passwordForm.current;
    const password = form['currentPassword']?.value;
    const newPassword = form['newPassword']?.value;
    const confirmPassword = form['confirmPassword']?.value;
    //Reg Ex for finding script tags
    const chkForTagsRegEx = new RegExp('.*\\<[^>]+>.*'); //NOSONAR

    const isValidField = validateFields.validate({
      textField: password,
      password: newPassword,
      confirmPassword: confirmPassword,
    }, validationOptions);

    if (isValidField?.error) {
      let errorKeys: any = { password: '', confirm_password: '', new_password: '' };
      isValidField?.error?.details?.forEach((val) => {
        if (val?.context?.key === 'textField') {
          errorKeys.password = val?.message;
        }
        if (val?.context?.key === 'password' || val?.context?.key === 'confirmPassword') {
          errorKeys.confirm_password = val?.message;
          errorKeys.new_password = val?.message;
        }
      });
      setErrors(errorKeys);
    } else if (newPassword && newPassword.length > 128) {
      setErrors({ new_password: 'Password should not be more than 128 characters' });//NOSONAR
    } else if (chkForTagsRegEx.test(newPassword)) {
      setErrors({ new_password: 'Password should not contain HTML Tags' });//NOSONAR
    } else if (chkForTagsRegEx.test(password)) {
      setErrors({ password: 'Password should not contain HTML Tags' });//NOSONAR
    } else if (chkForTagsRegEx.test(confirmPassword)) {
      setErrors({ confirm_password: 'Password should not contain HTML Tags' });//NOSONAR
    } else {
      setBtnDisable(true);
      let payload: any = { old_password: password, new_password: newPassword };
      Api.changePassword(payload)
        .then((res) => {
          if (res.success) {
            setErrors({});
            dispatch({ type: 'PURGE_STATE', payload: {} });
            Api.logout();
            reactGAEvents('my-account', 'modal-change-password');
            setSuccessMsg(true);
            setBtnDisable(false);
            setTimeout(() => {
              setSuccessMsg(false);
            }, 5000);
          } else {
            const { data }: any = res;
            setErrors({ password: data.message });
            setBtnDisable(false);
          }
        })
        .catch((err) => {
          console.log('error', err);
          setErrors(err);
          setBtnDisable(false);
        });
    }
  };

  const onPasswordModalOpen = () => {
    setShowPasswordModal(true);
  };

  const onEmailPref = () => {
    setBtnDisable(true);
    reactGAEvents('my-account', 'update-email');
    let payload = { job_opportunities_reminders_opt_in: jobOpt_in, profile_reminders_opt_in: profileOpt_in, assessment_reminders_opt_in: assessmentOpt_in };
    const uuid = data.uuid;
    Api.updateEmailPreferences(payload, uuid)
      .then((res) => {
        if (res.success) {
          // empty
          setErrors({});
          props.onSuccess(true);
          setBtnDisable(false);
          window.scrollTo(0, 0);
        } else {
          console.log(res);
          const { data }: any = res;
          setErrors(data);
          setBtnDisable(false);
        }
      })
      .catch((err) => {
        const { data } = err;
        console.log(err);
        setErrors(data);
        setBtnDisable(false);
      });
  };

  const onSaveFormProcess = () => {
    reactGAEvents('my-account', 'account-details-save');
    onEmailPref();
  };

  const goToZendesk = (event: any) => {
    event.stopPropagation();
    reactGAEvents('my-account', 'delete-account');
    window.open('https://heidricksupport.zendesk.com/', '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <CssBaseline />
      <Box sx={pageWrapper(isMobile, themeMode)}>
        <Typography sx={headerStyle(themeMode)}>Account Details</Typography>
        <Divider sx={titleUnderline(themeMode)} />

        <Box sx={textFieldWrap}>
          <HDTextField
            type={'email'}
            placeholder={'Email'}
            id={'emailAddress'}
            lblText={'Email'}
            value={email}
            readOnly={true}
            error={errors?.email}
            errorMsg={errors?.email ? errors.email : null}
          />
          <HDTextField type='text' placeholder={'***************'} id={'password'} lblText={'Password'} value={'***************'} sx={StyledEmail} />
          <HDButton text='Change Password' variant='outlined' sx={passwordButtonStyle} onClick={onPasswordModalOpen} />
        </Box>

        <Typography sx={headerStyleEmail}>Email Preferences</Typography>
        <Box>
          <HDCheckBox
            value={jobOpt_in}
            checked={jobOpt_in}
            onChange={({ target }) => {
              reactGAEvents('my-account', 'email-preference-ok-to-contact-job-opportunities');
              setJobOpt_in(target.checked);
            }}
            showBackground
            labelTxt={'You can email me about job opportunities, Heidrick offerings, and leadership insights.'}
            wrapperSX={chkBoxStyle}
            id='ok-to-email-me'
          />
          <HDCheckBox
            value={profileOpt_in}
            checked={profileOpt_in}
            onChange={({ target }) => {
              reactGAEvents('my-account', 'email-profile-reminders');
              setProfileOpt_in(target.checked);
            }}
            showBackground
            labelTxt={'Remind me to manage and update my profile'}
            wrapperSX={chkBoxStyle}
            id='remind-me-to-manage'
          />
          <HDCheckBox
            value={assessmentOpt_in}
            checked={assessmentOpt_in}
            onChange={({ target }) => {
              reactGAEvents('my-account', 'email-assessment-reminders');
              setAssessmentOpt_in(target.checked);
            }}
            showBackground
            labelTxt={'Remind me to complete my assessments'}
            wrapperSX={chkBoxStyle}
            id='remind-me-to-complete-assessments'
          />
        </Box>

        <Box sx={btnWrapper}>
          <HDButton text={'Save'} disabled={btnDisable} endIcon={btnDisable ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={btnIconStyle(themeMode)} />} variant={'contained'} sx={btnStyle(themeMode)} onClick={onSaveFormProcess} showAnimation={true} />
          <Link onClick={goToZendesk} sx={sxDeleteButton} tabIndex={0}>
            Delete Account
          </Link>
        </Box>
        <HDModal
          open={showPasswordModal}
          handleModal={() => {
            setShowPasswordModal(false);
            setErrors({});
          }}
          title='Change password'
          description={
            <>
              {SuccessMsg && <HDNotificationBanner style={bannerStyled} content={'New Password  have been successfully updated'} severity={'info'} variant={'filled'} borderRadius='0px' />}
              <form ref={passwordForm}>
                <HDTextField id='current-password' lblText='Current password' type='password' name='currentPassword' error={errors?.password} errorMsg={errors?.password ? errors.password : null} sx={textFieldStyle} />
                <HDTextField id='new-password' lblText='New password' type='password' name='newPassword' error={errors?.new_password} errorMsg={errors?.new_password ? errors.new_password : null} sx={textFieldStyle} />
                <HDTextField id='confirm-password' lblText='Confirm password' type='password' name='confirmPassword' error={errors?.confirm_password} errorMsg={errors?.confirm_password ? errors.confirm_password : null} sx={textFieldStyle} />
              </form>
            </>
          }
          actions={
            <>
              <HDButton text='Change Password' disabled={btnDisable} endIcon={btnDisable ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={btnIconStyle(themeMode)} />} variant='contained' sx={btnStyle(themeMode)} onClick={onSavePassword} showAnimation={true} />
              <HDButton
                text='Cancel'
                variant='outlined'
                onClick={() => {
                  reactGAEvents('my-account', 'modal-cancel');
                  setShowPasswordModal(false);
                  setErrors({});
                }}
              />
            </>
          }
        />
      </Box>
    </>
  );
}

export default AccountDetails;
