import { Box, Container, Typography, useMediaQuery, useTheme, Divider, CssBaseline } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Loader from '../../commons/Loader';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import {
  addEmailAddress,
  addPassword,
  confirmPassword,
  privacyPolicy,
  termsOfUse,
  mailmeAboutJob,
  createAccountBtn,
  dividerWithOr,
  LinkedInBtn,
  alreadyHavingAccount
} from '../../commons/SignUpComponents';
import { useNavigate } from 'react-router';
import { ContainerStyle, contentWrapStyle, backImage, styledCardWrappers, titleStyle, formWrapper, Stepper, dividerStyle } from './NewSignupStyles';
import Api from '../../Api';
import { validateFields, validationOptions } from '../../ValidationSchema';
import { reactGAEvents, backgroundTopRight, backgroundTopRightDark } from '../../commons/commonFunctions';

const NewSignUp = () => {
  const theme = useTheme();
  const themeStyle = theme.palette.mode;
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfrim, setPasswordConfirm] = useState('');
  const [privacyPolicyChecked, setPrivacyPolicyCheck] = useState(false);
  const [termsOfUseChecked, setTermsOfUseChecked] = useState(false);

  const [textOrCall, setTextOrCall] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const isLightMode = theme.palette.mode === 'light' ? 'light' :'dark';
  const isMobileOrTablet = isMobile || isTablet;

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    console.log('GA CREATE ACCOUNT');
    reactGAEvents('profile-register', 'create-account');

    const isValidField = validateFields.validate(
      {
        email: email,
        password: password,
        confirmPassword: passwordConfrim,
        privacyPolicy: privacyPolicyChecked,
        termsOfUse: termsOfUseChecked,
      },
      validationOptions
    );

    if (isValidField?.error) {
      let errorKeys: any = { email: '', password: '', privacy_policy: '', terms_of_use: '' };
      const contextKeyMapping: any = {
        email: 'email',
        password: 'password', //NOSONAR
        confirmPassword: 'password', //NOSONAR
        privacyPolicy: 'privacy_policy',
        termsOfUse: 'terms_of_use',
      };
      errorKeys = isValidField.error.details?.reduce((acc:any, val:any) => {
        const mappedKey:any = contextKeyMapping[val?.context?.key];
        if (mappedKey) {
          acc[mappedKey] = val?.message;
        }
        return acc;
      }, { email: '', password: '', privacy_policy: '', terms_of_use: '' });
      setErrors(errorKeys);
      return;
    }

    setErrors({});
    const payload: any = {
      email: email,
      password: password,
      job_opportunities_reminders_opt_in: textOrCall,
      privacy_policy: privacyPolicyChecked,
      terms_of_use: termsOfUseChecked,
    };

    setLoading(true);
    Api.register(payload)
      .then((response) => {
        if (response.success) {
          navigate('/account-activation');
        } else {
          const { data }:any = response;
          setErrors(data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (isAuth) navigate('/home');
  }, []);

  const backgroundLight = isMobileOrTablet ? 'url(../res/NewPasswordBGLightMobile.svg)' : 'url(../res/NewPasswordBGLight.svg)';
  const backgroundDark = isMobileOrTablet ? 'url(../res/NewPasswordBGDarkMobile.svg)' : 'url(../res/NewPasswordBGDark.svg)';

  return (
    <>
      <CssBaseline />
      {loading && <Loader />}
      <Header />
      <Box sx={themeStyle === 'light' ? { ...backgroundTopRight, display: 'grid' } : { ...backgroundTopRightDark, display: 'grid' }}>
        <Container maxWidth="lg" sx={ContainerStyle(isMobile, isTablet)}>
          <Box
            sx={{
              background: isLightMode ? backgroundLight : backgroundDark,
              ...backImage(isMobile, isTablet),
            }}
          ></Box>
          <Box sx={contentWrapStyle(isMobile, isTablet)}>
            {Stepper(0)}
            <Box sx={styledCardWrappers(isMobile, isTablet)}>
              <Typography sx={titleStyle(themeStyle)}>Start by creating your Heidrick for Leaders account.</Typography>
              <Box sx={formWrapper}>
                {addEmailAddress(reactGAEvents, email, setEmail, errors)}
                {addPassword(reactGAEvents, password, setPassword, errors)}
                {confirmPassword(reactGAEvents, passwordConfrim, setPasswordConfirm, errors)}
                {privacyPolicy(themeStyle, reactGAEvents, privacyPolicyChecked, setPrivacyPolicyCheck, errors)}
                {termsOfUse(themeStyle, reactGAEvents, termsOfUseChecked, setTermsOfUseChecked, errors)}
                {mailmeAboutJob(reactGAEvents, textOrCall, setTextOrCall)}
                {createAccountBtn(themeStyle, reactGAEvents, handleSubmit)}
                {dividerWithOr(themeStyle)}
                {LinkedInBtn(themeStyle, reactGAEvents, setErrors)}
                <Divider sx={dividerStyle(themeStyle)} />
                {alreadyHavingAccount(themeStyle, reactGAEvents, navigate, isMobile)}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default NewSignUp;
