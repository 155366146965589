import React from 'react';
import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  CssBaseline
} from '@mui/material';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDButton from '../../hd-ui-kit/HDButton';
import { useNavigate } from 'react-router';
import { reactGAEvents, backgroundTopRight, backgroundTopRightDark, titleStyle, styledText, styledSignInBtn, styledBoxMainWrapper, styledDividerLeft } from '../../commons/commonFunctions';

const AccountActivation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const themeMode = theme.palette.mode;

  const MainStyledBox = {
    display: 'flex',
    flexDirection: 'column',
  };

  const StyledForgetWrapper = {
    textAlign: 'left',
    marginTop: '20px',
  };

  const backColor = (theme.palette.mode === 'light') ? { ...backgroundTopRight } : { ...backgroundTopRightDark };

  const handleOnClick = () => {
    reactGAEvents('account-activation', 'return-to-sign-in');
    navigate('/login');
  };

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ ...backColor, display: 'grid' }}>
        <Box sx={styledBoxMainWrapper(isMobile, themeMode)}>
          <Container maxWidth="lg">
            <Box sx={styledDividerLeft(isMobile, theme)}>
              <Box>
                <Box sx={MainStyledBox}>
                  <Typography variant="h2" sx={titleStyle(isMobile, themeMode)}>
                    Please click the activation link we sent to your email
                  </Typography>
                  <Typography variant="h2" sx={styledText(themeMode)}>
                    In order to activate your account and complete your profile, please click the link
                    sent to your email.
                  </Typography>
                </Box>
                <Box sx={StyledForgetWrapper}>
                  <Box>
                    <Box>
                      <HDButton
                        sx={styledSignInBtn(themeMode)}
                        variant={'text'}
                        text={'Return to sign-in screen'}
                        onClick={() => handleOnClick()}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default AccountActivation;
